import React, { useState, useCallback, useEffect, useRef } from 'react';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { getImageSize } from 'react-image-size';

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => { 
    images[item.replace('./', '')] = r(item); 
  });
  return images;
}

const images = importAll(require.context('../../img/gallery', false, /\.(png|jpe?g|webp)$/));

const PhotoGallery = ({}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photos, setPhotos] = useState([]);
  const galleryRef = useRef(null);

  useEffect(() => {
    const loadImage = async (imageName) => {
      console.log("loading image: ", images[imageName])
      const img = new Image();
      img.src = images[imageName];
      const { width, height } = await getImageSize(img.src);
      setPhotos(prevPhotos => [...prevPhotos, {
        src: img.src,
        width,
        height,
      }]);
    };
    Object.keys(images).forEach(imageName => loadImage(imageName));
  }, []);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setViewerIsOpen(false);
    setCurrentImage(0);
  };

  return (
    <>
      <div ref={galleryRef}>
        <Gallery photos={photos} onClick={openLightbox} />
      </div>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

export default PhotoGallery;
