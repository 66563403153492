import React, {useEffect} from 'react';
import Style from './Home.module.scss';
import me from '../../img/self.png';
import classNames from 'classnames';
import EmojiBullet from "./EmojiBullet";
import { Box } from "@mui/material";
import { info } from "../../info/Info"
import ReactDOM from 'react-dom';
import Terminal from "./Terminal";
import { SocialIcon } from 'react-social-icons';
import stackImg from '../../img/stack.jpg';

//GA
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-G5D1HFXEJW"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export default function Home() {

   const webTitle = "Leo's Lab"

   useEffect(() => {
       document.title = webTitle;
     }, []);


   //linkedinTracker
   const eventTrack = (category, action, label) => {
      console.log("GA event:", category, ":", action, ":", label);
      ReactGA.event({
         category: category,
         action: action,
         label: label,
      })
   }


   function stackTerminal() {
      return <>
         <p>
            <span style={{ color: "#00FFFF" }}>## How is this website work</span> <br/>
            <span style={{ color: info.baseColor }}>this-website $</span> cat web-description.txt 
            {/* <span style={{ color: info.baseColor }}>this-website $</span> cat web-description.txt && xdg-open /cicd-diagram.png  */}
         </p>
         <p className={Style.aboutWeb}>
            * I created this website is for my DevOps testing :)<br/>
            * This is a static website made with React. <br/>
            * Hosted on Nginx in k3s (in Cloud Engine on Google Cloud Platform). <br/>
            * Developed & deployed with CI/CD pipelines in Jenkins (on Oracle Cloud). <br/>
            * Cloudflare acts as a reverse proxy and web application firewall. <br/>
            * Let's containerize everything!
            <br/><br/>
         </p>
         {/* <Box className={classNames(Style.stack)}
            alt={'used stack in the web'} style={{ background: info.gradient }}
            component={'img'} src={stackImg} width={'100%'} heigh={'auto'}
            onClick={() => window.open(stackImg)}
            p={'0.4rem'} mb={{ xs: '1rem', sm: 0 }} mr={{ xs: 0, md: '2rem' }} /> */}
      </>;
   }

   return (
      <>
         <Box component={'main'} display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} alignItems={'center'}
            justifyContent={'center'} minHeight={'calc(100vh - 175px)'}>
            <Box className={classNames(Style.avatar, Style.shadowed)} alt={'image of developer'} style={{ background: info.gradient }} component={'img'} src={me} width={{ xs: '35vh', md: '40vh' }}
               height={{ xs: '35vh', md: '40vh' }}
               borderRadius={'50%'} p={'0.75rem'} mb={{ xs: '1rem', sm: 0 }} mr={{ xs: 0, md: '2rem' }}
            />
            <Box>

               <h1>Hi, I'm <span style={{ background: info.gradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>{info.firstName} {info.lastName}</span><span className={Style.hand}>🤚</span>
               </h1>
               <h2>Nice to meet you here.</h2>

               <Box component={'ul'} p={'0.8rem'}>
                  {info.miniBio.map((bio, index) => (
                     <EmojiBullet key={index} emoji={bio.emoji} text={bio.text} />
                  ))}
               </Box>

               {/* Linkedin */}
               {/* <Box className={Style.social} display={'flex'} gap={'1.5rem'} fontSize={{ xs: '2rem', md: '2.5rem' }}>
                  {info.socials.map((social, index) => ( 
                     <SocialIcon key={index} url={social.link} icon={social.icon} label={social.label} network={social.network} onClick={eventTrack.bind(this, "Home Screen", "Social Linked Button", "Button")} />
                  ))}
               </Box> */}

               {/* Rickrol */}
               <Box className={Style.social} display={'flex'} gap={'1.5rem'} fontSize={{ xs: '2rem', md: '2.5rem' }}>
                  <SocialIcon url="https://www.youtube.com/watch?v=dQw4w9WgXcQ" icon="" label = "inkedin" network="linkedin" onClick={eventTrack.bind(this, "Home Screen", "Rickrolled", "Button")} />
               </Box>
               
            </Box>
         </Box>

         {/* Below is terminal view */}
         <Box className={Style.homeTerminal} display={'flex'} width={{ xs: '100%', md: '100%' }} flexDirection={'column'} alignItems={'center'} mt={'0rem'}>
            <Terminal text={stackTerminal()} />
         </Box>
      </>
   )
}
