import React, {lazy, Suspense, useEffect  ,useState, useCallback } from 'react';
import { render } from "react-dom";
import { Box, Grid } from "@mui/material";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
// import { photos } from "./Photos";
import PhotoGallery from "./PhotoGallery";

const webTitle = "Leo's Album";

export default function Album() {
  useEffect(() => {
    document.title = webTitle;
  }, []);

  return (
    <>
      <div>
        <Box display={'block'} flexDirection={'column'} alignItems={'center'}
          py={'1.5rem'} width={'100%'}>
          <p style={{ textAlign: "center" }}>Not a photographer, just testing my programming skill :p .</p>
        </Box>
        <PhotoGallery />

      </div>
    </>
    );
    
};