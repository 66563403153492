import React from 'react';
import './App.module.scss';
import BaseLayout from "./components/BaseLayout";
import About from "./components/about/About";
import {BrowserRouter, Routes, Route} from "react-router-dom";

function App() {
   return (
      <div>
         <BrowserRouter>
            <BaseLayout/>
         </BrowserRouter>
      </div>
   );
}


export default App;
