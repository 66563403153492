import React, {useEffect} from 'react';
import Style from './About.module.scss';
import Terminal from "./Terminal";
import { Box } from "@mui/material";
import { info } from "../../info/Info";

export default function About() {
    const webTitle = "About Leo"

    useEffect(() => {
        document.title = webTitle;
      }, []);

    
    const firstName = info.firstName.toLowerCase()

    function aboutMeText() {
        return <>
            <p className={Style.aboutMe}><span style={{ color: info.baseColor }}>{firstName}-{info.lastName.toLowerCase()} $</span> cat
                about-{firstName}.txt </p>
            <p className={Style.aboutMe}>
                {info.bio}
            </p>
        </>;
    }

    function skillsText() {
        return <>
            <p className={Style.skills} ><span style={{ color: info.baseColor }}>{firstName}-{info.lastName.toLowerCase()} $</span> cd skills
            </p>

            <p className={Style.skills} ><span style={{ color: info.baseColor }}>skills
                <span className={Style.green}></span> $</span> ls
            </p>

            <p className={Style.skills} style={{ color: "#6495ED" }}> Tools: </p>

            <ul className={Style.skills} >
                {info.skills.tools.map((proficiency, index) => <li key={index}>- {proficiency}</li>)}
            </ul>

            <p className={Style.skills} style={{ color: "#6495ED" }}>Coding: </p>

            <ul className={Style.skills}>
                {info.skills.languages.map((skill, index) => <li key={index}>- {skill}</li>)}
            </ul>
        </>;
    }


    function certificates() {
        return <>
            <p className={Style.certificates}><span style={{ color: info.baseColor }}>{firstName}-{info.lastName.toLowerCase()} $</span> ls certificates # List my certs
            </p>

            <ul className={Style.certificates}>
                {info.certificates.map((certificates, index) => (
                    <li key={index}><Box component={'span'} mr={'0rem'}>  </Box>  <img height="75px" src={certificates.image} /> {certificates.name} </li>
                ))}
            </ul>

        </>;
    }

    function miscText() {
        return <>
            <p className={Style.miscText} ><span style={{ color: info.baseColor }}>{firstName}-{info.lastName.toLowerCase()} $</span> cd
                hobbies/interests</p>
            <p className={Style.miscText} ><span style={{ color: info.baseColor }}>hobbies/interests <span
                className={Style.green}></span> $</span> ls</p>
            <ul className={Style.miscText}>
                {info.hobbies.map((hobby, index) => (
                    <li key={index}><Box component={'span'} mr={'1rem'}>{hobby.emoji}</Box>{hobby.label}</li>
                ))}
            </ul>
        </>;
    }


    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'}>
            <Terminal text={aboutMeText()} />
            <Terminal text={certificates()} />
            <Terminal text={skillsText()} />
            <Terminal text={miscText()} />
        </Box>
    )
}