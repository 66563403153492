import React, { useState } from 'react';
import Style from './BaseLayout.module.scss'
import Navbar from "./Navbar";
import Home from "./home/Home";
import About from "./about/About";
import Album from "./album/Album";
import { Route, Routes } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import ReactGA from 'react-ga4';
// import { photos } from "./album/Photos";


const TRACKING_ID = "G-G5D1HFXEJW"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export default function BaseLayout() {
   let [darkMode, setDarkMode] = useState(true);

   function handleClick() {
      setDarkMode(!darkMode);
   }

   return (
      <Box className={darkMode ? Style.dark : Style.light}>
         <Grid container display={'flex'} flexDirection={'column'} minHeight={'100vh'}
            justifyContent={'space-between'}>
            <Grid item>
               <Navbar darkMode={darkMode} handleClick={handleClick} />
            </Grid>
            <Grid item flexGrow={1}>
               <Routes>
                  <Route exact path={'/'} element={<Home />} />
                  <Route exact path={'/about'} element={<About />} />
                  <Route exact path={'/album'} element={<Album />} />
               </Routes>
            </Grid>
            <Grid item>
               <Box component={'footer'} display={'flex'} flexDirection={'column'} alignItems={'center'}
                  py={'1.5rem'} sx={{ opacity: 0.7 }} width={'100%'}>
                  <p style={{ textAlign: "center" }}>Special thanks:<br />this website was developed based on the template created with &hearts; by <a href={'https://paytonpierce.dev'}>Payton Pierce</a>,<br /> she made this website possible.</p>
                  <p>&copy; 2023</p>
               </Box>
            </Grid>
         </Grid>

      </Box>
   )
}

