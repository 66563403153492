import self from "../img/self.png"

import awsSolutionArchAsso from "../img/aws-solution.png"
import awsDeveloperAsso from "../img/aws-developer.png"
import awsDevOpsPro from "../img/aws-devops-pro.png"
import azureFund from "../img/azure-fund.png"
import azureAdmin from "../img/azure-admin.png"
import azureAIEng from "../img/azure-aieng.png"
import awsSysOpsAsso from "../img/aws-sysops.png"
import gcpEngAsso from "../img/gcp-eng.png"

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */


/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
    firstName: "Leo",
    lastName: "Yip",
    initials: "LY", // the example uses first and last, but feel free to use three or more if you like.
    position: "Cloud Engineer",
    selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            emoji: '☕',
            text: 'Fueled by coffee'
        },
        {
            emoji: '🇭🇰',
            text: 'Based in Hong Kong'
        },
        {
            emoji: "☁️",
            text: "Cloud Engineer"
        },
        {
            emoji: "⛺",
            text: "Camper"
        },
        {
            emoji: "🧂",
            text: "Msg is king of flavor"
        },
        {
            //email block
            emoji: "📧",
            text: "Contace me: leo@lyip.win",
        }
    ],
    socials: [
        // {
        //     link: "https://www.linkedin.com/in/l-yip",
        //     label: 'linkedin',
        //     network: "linkedin"
        // },
        // Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
        // Just change the links so that they lead to your social profiles.

    ],
    bio: "Hello! I'm a Cloud Engineer in a consulting firm. I love to drink coffee, camping, and drink coffee when camping.",
    skills:
    {
        tools: ['AWS', 'Azure', 'DevSecOps', 'Terraform', 'Git', 'Github Action', 'Serverless', 'Jenkins', "Kubectl", "Ansible", "CI/CD", "VMware TKGs Kubernetes"],
        languages: ['Nodejs', 'Python', "Groovy", "HCL"]
    }
    ,
    hobbies: [
        {
            label: 'reading <- just kidding',
            emoji: '📖'
        },
        {
            label: 'camping',
            emoji: '⛺'
        },
        {
            label: 'cycling',
            emoji: '🚲'
        },
        {
            label: 'coding',
            emoji: '👨‍💻'
        },
        {
            label: 'coffee brewing',
            emoji: '☕'
        }
        // Same as above, change the emojis to match / relate to your hobbies or interests.
        // You can also remove the emojis if you'd like, I just think they look cute :P
    ],
    certificates: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            image:awsDevOpsPro,
            name: 'AWS Certified DevOps Engineer – Professional'
        },
        {
            image: awsSolutionArchAsso,
            name: 'AWS Certified Solutions Architect – Associate'
        },
        {
            image: awsDeveloperAsso,
            name: 'AWS Certified Developer – Associate'
        },
        {
            image: awsSysOpsAsso,
            name: 'AWS Certified SysOps Administrator – Associate'
        },
        {
            image: azureAIEng,
            name: 'Microsoft Certified: Azure AI Engineer Associate'
        },
        {
            image: azureAdmin,
            name: 'Microsoft Certified: Azure Administrator Associate'
        },
        {
            image: azureFund,
            name: 'Microsoft Certified: Azure Fundamentals'
        },
        {
            image: gcpEngAsso,
            name: 'Google Cloud Certified: Associate Cloud Engineer'
        }
    ]
}